import { useTheme } from "styled-components";
import { IOrderLine } from "@ecp-redux/dto/cart.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../../global/components/ImageWrapper/ImageWrapper";
import { useImageSize } from "../../../global/components/ImageWrapper/ImageWrapper.methods";
import { formatPrice } from "../../../helpers/helpers";
import ChangeProductQuantity from "../../../shared/components/domain/Cart/ChangeProductQuantity/ChangeProductQuantity";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useBoxContext } from "../../../structure/Contexts/BoxContext";
import { TBoxMiniCartProps } from "../BoxMiniCart.types";
import { StyledProductWrapper } from "./StyledProductWrapper.styled";
import { EVENT_ORIGIN } from "../../../boxes/analytics/const";
import { useLanguageCurrencyContext } from "@ecp-boxes/structure/Contexts/LanguageCurrencyContext";

interface IMiniCartProductProps {
  content?: IOrderLine[];
}

const MiniCartProduct: React.FC<IMiniCartProductProps> = ({ content }) => {
  const {
    advanceSettings: { settings: globalSettings },
  } = useTheme() as IThemeState;
  const [imgWrapper, { width: imageWidth }] = useImageSize();

  const { currencyShort } = useLanguageCurrencyContext();

  const { messages, settings } = useBoxContext<TBoxMiniCartProps>();

  return (
    <div className="mini-cart-product-container">
      {content?.map((item) => {
        return (
          <StyledProductWrapper
            ref={imgWrapper}
            key={item.productSku}
            className="mini-cart-product-container__single-product-container"
            {...settings}
          >
            <div>
              <div className="mini-cart-product-container__single-product-container__image">
                <ImageWrapper
                  imageUrl={item.image ?? globalSettings.dynamicBoxImagePlug}
                  seoDescription={item.productName}
                  imageFit={false}
                  imageAlignment={{
                    horizontal: AlignmentHorizontalOption.CENTER,
                    vertical: AlignmentVerticalOption.CENTER,
                  }}
                  width={imageWidth}
                />
              </div>
            </div>
            <div className="mini-cart-product-container__single-product-container__product-data">
              <div>
                <StyledText
                  renderAs="p"
                  style={{ display: "block" }}
                  className="mini-cart-product-container__single-product-container__title"
                  $settings={{
                    font: settings.mini_cart_dropped_product_title_typo,
                    text: {
                      color: settings.mini_cart_dropped_product_title_color,
                    },
                  }}
                >
                  {item.productName}
                </StyledText>
                <div className="mini-cart-product-container__single-product-container__price-container">
                  <div>
                    <div>
                      <div>
                        <StyledText
                          className="mini-cart-product-container__single-product-container__price"
                          $settings={{
                            font: settings.mini_cart_dropped_single_product_price_typo,
                            text: {
                              color:
                                settings.mini_cart_dropped_single_product_price_color,
                            },
                          }}
                        >
                          <p>{formatPrice(item.salePrice, currencyShort)}</p>
                        </StyledText>
                      </div>
                      {item.salePrice !== item.price && (
                        <StyledText
                          className="mini-cart-product-container__single-product-container__price-line-through"
                          $settings={{
                            font: settings.mini_cart_dropped_single_product_price_line_through_typo,
                            text: {
                              color:
                                settings.mini_cart_dropped_single_product_price_line_through_color,
                            },
                          }}
                        >
                          <p>{formatPrice(item.price, currencyShort)}</p>
                        </StyledText>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mini-cart-product-container__single-product-container__change-quantity-container">
                <ChangeProductQuantity
                  className="mini-cart-product-container__single-product-container__change-quantity"
                  inputQuantityStyle={
                    settings.mini_cart_dropped_input_quantity_style
                  }
                  product={item}
                  errorSnackbarStyle={globalSettings.snackbarApiError}
                  successSnackbarStyle={settings.add_to_cart_success_snackbar}
                  successSnackbarMessage={messages.add_to_cart_success_message}
                  boxType={EVENT_ORIGIN.BOX_MINI_CART}
                />
                <StyledText
                  className="mini-cart-product-container__single-product-container__line-price"
                  $settings={{
                    font: settings.mini_cart_dropped_single_product_line_price_typo,
                    text: {
                      color:
                        settings.mini_cart_dropped_single_product_line_price_color,
                    },
                  }}
                >
                  {formatPrice(
                    item.salePrice && item.quantity
                      ? item.salePrice * item.quantity
                      : 0,
                    currencyShort
                  )}
                </StyledText>
              </div>
            </div>
          </StyledProductWrapper>
        );
      })}
    </div>
  );
};

export default MiniCartProduct;
