import { TButtonId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import styled from "styled-components";
import { css } from "styled-components";

export const StyledChangeProductQuantity = styled.div<{
  className: string;
  disabled: boolean | undefined;
  buttonQuantityStyle?: TButtonId;
}>`
  display: flex;
  align-self: flex-end;

  opacity: ${(props) => (props.disabled ? "0.2" : "1")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  ${(props) =>
    props.buttonQuantityStyle === undefined
      ? css`
          .${props.className}__minus-button {
            &:hover {
              background-color: #eeeeee;
            }

            &:disabled {
              pointer-events: none;
              opacity: 0.5;
            }
          }

          .${props.className}__plus-button {
            &:hover {
              background-color: #eeeeee;
            }

            &:disabled {
              pointer-events: none;
              opacity: 0.5;
            }
          }
        `
      : css`
          .icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 21px;
            width: 21px;
            cursor: pointer;
          }
        `}

  .${(props) => props.className}__count-input {
    text-align: center;
    min-width: 40px;
    max-width: 40px;
    padding-right: 10px;
  }
`;
