import {
  IBoxAddToCartMessages,
  IBoxAddToCartProps,
  IBoxAddToCartSettings,
} from "../../../../boxes/BoxAddToCart/BoxAddToCart.types";
import {
  IBoxMiniCartMessages,
  IBoxMiniCartSettings,
  TBoxMiniCartProps,
} from "../../../../boxes/BoxMiniCart/BoxMiniCart.types";
import {
  IBoxProductSliderMessages,
  IBoxProductSliderProps,
  IBoxProductSliderSettings,
} from "../../../../boxes/BoxProductSlider/BoxProductSlider.types";
import {
  BoxSearchResultsProps,
  IBoxSearchResultsMessages,
  IBoxSearchResultsSettings,
} from "../../../../boxes/BoxSearchResults/BoxSearchResults.types";
import {
  IBoxCartStepOneMessages,
  IBoxCartStepOneProps,
  IBoxCartStepOneSettings,
} from "../../../../boxes/Cart/BoxCartStepOne/BoxCartStepOne.types";
import { isErrorByCode } from "../../../../helpers/helpers";
import { useBoxContext } from "../../../../structure/Contexts/BoxContext";
import { useOpenPortalSnackbar } from "../../Snackbar/Snackbar";

type TSettings = IBoxProductSliderProps &
  BoxSearchResultsProps &
  IBoxAddToCartProps &
  TBoxMiniCartProps &
  IBoxCartStepOneProps;

export const useCart = () => {
  const { messages, settings } = useBoxContext<TSettings>();
  const { openPortalSnackbar } = useOpenPortalSnackbar();

  const openSnackbarWithCartError = (resp: any): void => {
    if (isErrorByCode(resp, [2004])) {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.product_error_limit_exceeded
      );
    } else if (isErrorByCode(resp, [2005, 2012, 2014])) {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.cart_quantity_limit_exceeded_warning
      );
    } else if (isErrorByCode(resp, [2015])) {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.product_error_stock_exceeded
      );
    } else if (isErrorByCode(resp, [2002])) {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.product_not_available_error
      );
    } else {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.add_to_cart_error_message
      );
    }
  };

  return { openSnackbarWithCartError };
};
