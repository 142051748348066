import styled from "styled-components";

export const StyledMiniCartSummaryWrapper = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: column;
`;

export const StyledMiniCartSummaryGroup = styled(StyledMiniCartSummaryWrapper)<{
  gap: string;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
  margin-bottom: ${({ gap }) => gap};
`;

export const StyledMiniCartSummaryElement = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledSummaryTotalPriceElement = styled(
  StyledMiniCartSummaryElement
)<{
  padding: string;
}>`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ padding }) => padding};
  border-top: 1px solid #d3d6de;
  gap: 40px;
`;
